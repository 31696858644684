import { FC, useEffect, useMemo, useState } from 'react';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import FormOwnership from '../../ownership/FormOwnership';
import { ClientFormUser, ClientFormUserResponse } from '../../../models/ClientFormUser';
import ClientFormService from '../../../services/ClientFormService';
import { OwnershipDisplayType } from '../../ownership/OwnershipProps';
import { ClientFormUserRole } from '../../../models/ClientFormUserRoles';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../../recoil/atoms/Auth';
import { Roles } from '../../../models/Role';
import { ClientFormStatus, FormStatusTag } from '../../../models/ClientFormStatus';
import { ClientForm } from '../../../models/ClientForm';
import Loader from '../Loader';
import ContextMenu from '../ContextMenu';
import { useTranslation } from 'react-i18next';
import FormUtils from '../../../utils/FormUtils';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import hasFormRole from '../../../utils/FormPermissionUtils';
import { FormType } from '../../../models/FormTypes';
import { ActionHighlightInfo } from '../../../models/Form';
import { Link, useLocation, useParams } from 'react-router-dom';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import LanguageUtils from '../../../utils/LanguageUtils';
import RiskUtils from '../../../utils/RiskUtils';
import FormRendererV2 from '../../form/renderer/FormRendererV2';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { FormRendererMode } from '../../../contexts/FormRendererDesignContextTypes';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { ParseKeys } from 'i18next';
import { DocumentResponse } from '../../../models/Document';
import StringUtils from '../../../utils/StringUtils';

export enum CollapsedFormType {
  Subform,
  AssociatedForm,
}

type BaseCollapsedFormProps = {
  formType: CollapsedFormType;
  formId: string;
  className?: string;
  onValidate?: (value: boolean) => void;
  onDelete?: (form: ClientForm) => void;
  canEdit?: boolean;
  previewMode?: boolean;
  actionNumber?: string;
};

type InlineCollapsedFormProps = BaseCollapsedFormProps & {
  showInline: boolean;
  expanded?: boolean;
};

type NonInlineCollapsedFormProps = BaseCollapsedFormProps & {
  showInline?: undefined;
  expanded?: never;
};

export type CollapsedFormProps = InlineCollapsedFormProps | NonInlineCollapsedFormProps;

export const CollapsedForm: FC<CollapsedFormProps> = (props) => {
  const { formId, className, onValidate, onDelete, showInline = false, canEdit = false, previewMode, actionNumber } = props;
  const expanded = showInline ? props.expanded : undefined;
  const [users, setUsers] = useState<ClientFormUser[]>([]);
  const user = useRecoilValue(currentUserAtom);
  const [currentFormUser, setCurrentFormUser] = useState<ClientFormUser | null>(null);
  const [clientForm, setClientForm] = useState<ClientForm | null>(null);
  const [inlineFormExpanded, setInlineFormExpanded] = useState(!!expanded);
  const { t } = useTranslation(['common', 'risk']);
  const params = useParams<{ formId: string }>();
  const client = useRecoilValue(currentClientAtom);
  const { containingModuleId, containingModuleSectionId } = useFormRendererInfo();
  const [isLoading, setIsLoading] = useState(true);
  const [failedMessage, setFailedMessage] = useState<ParseKeys<'common'>>('collapsed-form.error.unkown');
  const hasPermission = usePermissions();
  const { type } = useFormRendererInfo();

  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const toHighlight = useMemo<ActionHighlightInfo | undefined>(() => {
    const parts = query.get('highlight')?.split(',');
    if (!parts || parts.length !== 3) {
      return;
    }

    return {
      formId: parts[0],
      sectionId: parts[1],
      sourceId: parts[2],
    };
  }, [query]);

  useEffect(() => {
    if (toHighlight && toHighlight.formId === formId) {
      setInlineFormExpanded(true);
    }
  }, [formId, toHighlight]);

  // Keeping this for now ONLY for risk per question level
  const actionRisks = useMemo(() => {
    if (
      !(
        clientForm &&
        clientForm.status !== ClientFormStatus.NotStarted &&
        clientForm.status !== ClientFormStatus.InProgress &&
        clientForm.type === FormType.Asset
      )
    ) {
      return {};
    }

    const result: Record<string, number> = {};
    for (const section of clientForm.form.sections) {
      for (const action of section.actions) {
        result[action.id] = RiskUtils.calculateRiskForAction(action, clientForm.form).score;
      }
    }

    return result;
  }, [clientForm]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([ClientFormService.getFormUsers(formId, [404, 403]), ClientFormService.getForm(formId, [404, 403])])
      .then((res) => {
        setUsers(
          res[0].data.map((el: ClientFormUserResponse) => {
            return { ...el.user, role: el.role };
          }),
        );
        setClientForm(res[1].data);
      })
      .catch((err) => {
        if (err?.meta?.code === 403 || err.status === 403) {
          setFailedMessage('collapsed-form.error.permissions');
        } else if (err?.meta?.code === 404 || err.status === 404) {
          setFailedMessage('collapsed-form.error.notfound');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [formId, params.formId, setIsLoading, showInline, t]);

  useEffect(() => {
    clientForm?.type &&
      onValidate &&
      onValidate(
        clientForm.type === FormType.Asset && !showInline
          ? clientForm.status === ClientFormStatus.SubmittedForApproval || clientForm.status === ClientFormStatus.Completed
          : true,
      );
  }, [clientForm?.status, clientForm?.type, onValidate, showInline]);

  useEffect(() => {
    if (user) {
      const formUser = ClientFormService.getCurrentFormUser(users, user);
      setCurrentFormUser(formUser);
    }
  }, [user, users]);

  const onUsersChange = (users: ClientFormUser[]) => {
    users.sort((a, b) => (a.firstName && b.firstName ? (a.firstName > b.firstName ? 1 : -1) : -1));
    setUsers(users);
  };

  const onBarClick = () => {
    if (showInline) {
      setInlineFormExpanded((prev) => !prev);
    }
  };

  const isShared = useMemo(() => {
    if (clientForm) {
      return clientForm.clientId !== client?.id;
    }
    return false;
  }, [client?.id, clientForm]);

  const Wrapper = useMemo(() => {
    return showInline || !clientForm ? 'div' : Link;
  }, [clientForm, showInline]);

  const link = useMemo(() => {
    if (!clientForm) return '';

    if (clientForm.type === FormType.Resource) {
      return `/clients/${clientForm.clientId}/resources/${clientForm.form.id}/${clientForm.id}`;
    }

    return `/clients/${clientForm.clientId}/forms/${clientForm.id}`;
  }, [clientForm]);

  return (
    <div {...dataAttributeProps(props)} className={`border-grey-5 relative my-2 flex w-full flex-col rounded border-2 bg-white ${className}`}>
      {isLoading && (
        <div className="relative h-full min-h-14 w-full">
          <Loader size={12} />
        </div>
      )}
      {!isLoading && (
        <Wrapper
          to={link}
          className={`flex items-center ${!!clientForm && 'cursor-pointer'} px-4 py-3`}
          onClick={() => {
            if (onBarClick && !!clientForm) {
              onBarClick();
            }
          }}
        >
          {!!clientForm && (
            <>
              <div className="text-dpm-14 w-1/2" data-cy="form-title">
                <div className="font-medium text-black">
                  {actionNumber}{' '}
                  {clientForm.type === FormType.Document && (
                    <span>
                      {StringUtils.makePrefixWithNumber(
                        undefined,
                        (clientForm as DocumentResponse).documentNumber,
                        (clientForm as DocumentResponse).templateModuleTranslations,
                      )}
                      -
                    </span>
                  )}
                  {clientForm.subtitle || LanguageUtils.getTranslation('title', clientForm.form.translations)}
                  {FormUtils.formHostName({ clientId: clientForm.clientId, clientName: clientForm.clientName }, client?.id || '', ' ({client})')}
                  <span className="text-gray-3 text-dpm-14 ml-2">{`v${clientForm.majorVersion}`}</span>
                </div>
                {clientForm.subtitle && (
                  <div className="text-color-3 text-dpm-12">
                    {clientForm.type === FormType.Document &&
                      clientForm.form.isSystem &&
                      LanguageUtils.getTranslation('name', (clientForm as DocumentResponse).templateModuleTranslations)}
                    {!clientForm.form.isSystem && LanguageUtils.getTranslation('title', clientForm.form.translations)}
                  </div>
                )}
              </div>
              <div className="flex w-1/2 items-center">
                <div className="flex flex-grow justify-center">{!showInline && <FormStatusTag activity={clientForm} />}</div>
                <div className="mr-4 w-36 px-2">
                  {!!user && !showInline && (
                    <FormOwnership
                      users={users}
                      associatedId={formId}
                      size={OwnershipDisplayType.Tiny}
                      onUsersChange={onUsersChange}
                      requiresApproval={!!clientForm.form.requiresApproval}
                      requiresValidation={!!clientForm.form.requiresValidation}
                      requiresOwner={
                        clientForm.type === FormType.Document || (users.length > 0 && users.some((x) => x.role === ClientFormUserRole.Owner))
                      }
                      viewOnly={true}
                      displayLimit={3}
                    />
                  )}
                </div>
                <div className="flex w-10 items-center justify-end gap-2">
                  {onDelete &&
                    !isShared &&
                    (hasPermission(Roles.TeamLead) ||
                      hasFormRole(
                        [ClientFormUserRole.Owner, ClientFormUserRole.Validator, ClientFormUserRole.Approver, ClientFormUserRole.Contributor],
                        currentFormUser,
                      )) && (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                      <div onClick={(e) => e.stopPropagation()}>
                        <ContextMenu
                          data-cy={`form-context-menu-${formId}`}
                          items={[{ title: t('collapsed-form.delete'), onClick: () => onDelete(clientForm) }]}
                        />
                      </div>
                    )}
                  <ChevronIcon
                    type={showInline ? (inlineFormExpanded ? ChevronType.UP : ChevronType.DOWN) : ChevronType.RIGHT}
                    className="h-6 w-6 justify-end"
                  />
                </div>
              </div>
            </>
          )}
          {!clientForm && !isLoading && (
            <div className="text-dpm-14 w-full text-center">
              <div className="text-color-2 font-medium">{t('collapsed-form.error.title')}</div>
              <div className="text-color-3 text-dpm-12">{t(failedMessage) as string}</div>
            </div>
          )}
        </Wrapper>
      )}
      {showInline && (
        <div data-cy="inline-form" className={`${inlineFormExpanded ? 'visible' : 'hidden'} border-grey-5 border-t-2`}>
          {clientForm && (
            <FormRendererV2
              initialMode={previewMode ? FormRendererMode.PreviewView : FormRendererMode.EditView}
              clientForm={clientForm}
              canEdit={canEdit}
              actionRisks={actionRisks}
              moduleId={containingModuleId}
              moduleSectionId={containingModuleSectionId}
              renderAllSteps
              featureToggles={{
                disableModeToggle: true,
              }}
              number={actionNumber}
              type={type}
            />
          )}
        </div>
      )}
    </div>
  );
};
